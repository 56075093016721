<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filter_user_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_user_search"
              v-model="tableUser.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          order="3"
          class="px-1 mb-2"
        >
          <b-button
            size="sm"
            type="button"
            variant="success"
            @click="onCreateUser"
          >
            Add User
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableUser"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableUser.perPage"
            :current-page="tableUser.currentPage"
            :items="tableUserProvider"
            :fields="tableUser.fields"
            :sort-by.sync="tableUser.sortBy"
            :sort-desc.sync="tableUser.sortDesc"
            :sort-direction="tableUser.sortDirection"
            :filter="tableUser.filter"
            :filter-included-fields="tableUser.filterOn"
            :busy="tableUser.busy"
          >
            <template #cell(index)="row">
              {{ tableUser.currentPage * tableUser.perPage - tableUser.perPage + (row.index + 1) }}
            </template>

            <template #cell(is_approver)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(login_local)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(active)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell()="row">
              <div class="text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  @click="onEditUser(row.item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableUser.perPage"
            :options="tableUser.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableUser.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableUser.totalRows"
            :per-page="tableUser.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-user"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      :title="ModalUserTitle"
    >
      <ValidationObserver
        ref="formUser"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="first name"
                vid="first_name"
                rules="required|max:50"
              >
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="first_name"
                    v-model="user.first_name"
                    type="text"
                    autocomplete="off"
                    maxlength="50"
                    placeholder="enter first name here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="last name"
                vid="last_name"
                rules="required|max:50"
              >
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="last_name"
                    v-model="user.last_name"
                    type="text"
                    autocomplete="off"
                    maxlength="50"
                    placeholder="enter last name here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="notification email"
                vid="notification_email"
                rules="email|max:50"
              >
                <b-form-group
                  label="Notification Email"
                  label-for="notification_email"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="notification_email"
                    v-model="user.notification_email"
                    type="email"
                    autocomplete="off"
                    maxlength="50"
                    placeholder="enter notification email here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="username"
                vid="username"
                rules="required|max:50"
              >
                <b-form-group
                  label="Username"
                  label-for="username"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="username"
                    v-model="user.username"
                    type="text"
                    autocomplete="off"
                    maxlength="50"
                    placeholder="enter username here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="password"
                vid="password"
                :rules="vPassword"
              >
                <b-form-group
                  label="Password"
                  label-for="password"
                  description="Leave blank when not changing password."
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input
                      id="password"
                      v-model="user.password"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="enter password here"
                      :type="passwordType"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <b-input-group-append>
                      <b-button
                        class="pl-2 pr-1"
                        variant="success"
                        style="border-radius: 0px 5px 5px 0px"
                        :disabled="state.busy"
                        @click="togglePasswordType"
                      >
                        <i
                          v-if="state.password_visible"
                          class="ri-eye-line h5"
                        />
                        <i
                          v-else
                          class="ri-eye-off-line h5"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="password confirmation"
                vid="password_confirmation"
                :rules="vPasswordConfirmation"
              >
                <b-form-group
                  label="Password Confirmation"
                  label-for="password_confirmation"
                  description="Leave as blank when not changing password."
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="password_confirmation"
                    v-model="user.password_confirmation"
                    autocomplete="off"
                    maxlength="50"
                    placeholder="enter password confirmation here"
                    :type="passwordType"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="role"
                vid="role"
                rules="required"
              >
                <b-form-group
                  label="Role"
                  label-for="role"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-form-select
                    id="role"
                    v-model="user.role"
                    :options="lists.roles"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || Number(user.id) === 1"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select role here --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                vid="branch"
                name="branch"
                rules="required"
              >
                <b-form-group
                  label="Branch"
                  label-for="pr_branch"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-form-select
                    id="pr_branch"
                    v-model="user.branch"
                    :options="lists.branches"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select branch here --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="state.editing"
              cols="12"
            >
              <b-form-group
                class="mb-2"
              >
                <b-form-checkbox
                  id="is_active"
                  v-model="user.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="onValidateUser"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SharedListService, AdminUserService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'AdminUsers',

  middleware: ['auth', 'admin'],

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'Users'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        password_visible: false
      },
      lists: {
        roles: [],
        branches: [
          'Main Office',
          'CDO Office'
        ]
      },
      user: {
        id: null,
        username: null,
        first_name: null,
        last_name: null,
        notification_email: null,
        password: null,
        password_confirmation: null,
        role: null,
        branch: null,
        login_local: 0,
        active: 1
      },
      tableUser: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 9, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'username' },
          { mobile: 3, key: 'first_name' },
          { mobile: 4, key: 'last_name' },
          { mobile: 5, key: 'notification_email' },
          { mobile: 6, key: 'role.name', label: 'Role' },
          { mobile: 7, key: 'active', class: 'text-center' },
          { mobile: 8, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalUserTitle () {
      return this.state.editing ? 'Edit User' : 'Add User'
    },

    vPassword () {
      return `${this.state.editing ? '' : 'required|'}min:8`
    },

    vPasswordConfirmation () {
      return `${this.state.editing ? '' : 'required|'}confirmed:password`
    },

    passwordType () {
      return this.state.password_visible ? 'text' : 'password'
    }
  },

  mounted () {
    core.index()
    this.getRoles()
  },

  methods: {

    togglePasswordType () {
      this.state.password_visible = !this.state.password_visible
    },

    async tableUserProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableUser.busy = true
      return await AdminUserService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tableUser.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableUser.busy = false
      })
    },

    async getRoles () {
      return new Promise(resolve => {
        SharedListService.getRoles().then(({ data }) => {
          this.lists.roles = data.filter(
            role => Number(role.id) !== 1
          ).map(role => ({
            text: role.name,
            value: role.id
          }))
          resolve(data)
        })
      })
    },

    onCreateUser () {
      this.state.editing = false
      this.user.id = null
      this.user.username = null
      this.user.first_name = null
      this.user.last_name = null
      this.user.notification_email = null
      this.user.password = null
      this.user.password_confirmation = null
      this.user.role = null
      this.user.branch = null
      this.user.active = 1
      this.$bvModal.show('modal-user')
    },

    onEditUser (user) {
      this.state.editing = true
      this.user.id = user.id
      this.user.username = user.username
      this.user.first_name = user.first_name
      this.user.last_name = user.last_name
      this.user.notification_email = user.notification_email
      this.user.password = null
      this.user.password_confirmation = null
      this.user.role = user.role_id
      this.user.branch = user.branch
      this.user.active = user.active
      this.$bvModal.show('modal-user')
    },

    async onValidateUser (event) {
      event.preventDefault()
      await this.$refs.formUser.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: () => {
                if (this.state.editing) {
                  return '<h6>Save your Changes on this User?</h6>'
                }
                return '<h6>Create this User?</h6>'
              },
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPutUser()
                }
                return this.onPostUser()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostUser () {
      return new Promise(resolve => {
        AdminUserService.post(this.user).then(
          ({ data: { message } }) => {
            this.$refs.tableUser.refresh()
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-user')
            })
            resolve(message)
          }
        ).catch(({ message }) => {
          this.$refs.formUser.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    },

    async onPutUser () {
      return new Promise(resolve => {
        AdminUserService.put(this.user).then(
          ({ data }) => {
            const tableUserRow = QUERY.find(this.$refs.tableUser.localItems, { id: data.user.id })
            if (tableUserRow) {
              tableUserRow.username = data.user.username
              tableUserRow.first_name = data.user.first_name
              tableUserRow.last_name = data.user.last_name
              tableUserRow.notification_email = data.user.notification_email
              tableUserRow.role = data.user.role
              tableUserRow.role_id = data.user.role_id
              tableUserRow.branch = data.user.branch
              tableUserRow.active = data.user.active
              tableUserRow.updated_at = data.user.updated_at
            }
            this.swalSuccess(data.message).then(() => {
              this.$bvModal.hide('modal-user')
            })
            resolve(data)
          }
        ).catch(({ message }) => {
          this.$refs.formUser.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    }
  }
}
</script>
